<template>
  <x-dialog :proxy="importDialog">
    <div class="mt-3">
      <h3 style="color: #333">第一步：填写导入数据信息</h3>
      <div class="tip">
        <p>1、请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除。</p>
        <p>2、表格中字段均为必填项。</p>
      </div>
      <el-button type="text" @click="downTemplate">下载表格模板</el-button>
    </div>
    <div class="mt-3">
      <h3 style="color: #333">第二步：上传填好的信息表</h3>
      <div class="tip">
        文件后缀名必须为 <b>.xls</b> 或 <b>.xlsx</b>（即 <b>Excel</b> 格式），最多支持导入1千条数据。
      </div>
      <upload-excel type="text" show-import-count :on-success="excelSuccess" slot="reference" />
    </div>
  </x-dialog>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';
import UploadExcel from '@/components/UploadExcel';
import { RegExp as $regExp, Foundation } from '../../../../ui-utils';
import { handleDownload } from '@/utils';
import * as API_Goods from '@/api/goods';
import { type } from 'os';

export default {
  name: 'import-member',
  components: {
    UploadExcel,
    XDialog
  },
  props: {
    deliveryMethod: {//邮寄1   自提2
      type: Number,
      default: 2
    }
  },
  data () {
    return {
      importDialog: $xDialog.create({
        title: '导入操作',
        wrapperClass: 'import-member-dialog',
        beforeConfirm: () => this.uploadData(),
      }),
      importNum: 0,
      importData: [],
      importing: false,
      templateKey: ['goods_name', 'first_name', 'second_name', 'three_name', 'mktprice', 'revise_price', 'cost', 'quantity', 'offline_store_pay'],
      templateHeader: ['商品名称', '商品一级分类', '商品二级分类', '商品三级分类', '市场价', '销售价', '成本价', '总库存', '是否线下门店支付'],
      isExistErrorData: false,
    }
  },
  created () {
    if (!this.MixinIsFormEnterprise && this.deliveryMethod == 2) {//单独登录隐藏成本价
      this.templateKey.forEach((key, index) => {
        if (key === 'cost') {
          this.templateKey.splice(index, 1);
        }
      })
      this.templateHeader.forEach((key, index) => {
        if (key === '成本价') {
          this.templateHeader.splice(index, 1);
        }
      })
    }
  },
  methods: {
    show () {
      this.importNum = 0;
      this.importData = [];
      this.importing = false;
      this.importDialog.display();
      console.log(this.deliveryMethod);
      if (this.deliveryMethod == 1) {
        this.templateKey = ['goods_name', 'first_name', 'second_name', 'three_name', 'mktprice', 'revise_price', 'cost', 'quantity']
        this.templateHeader = ['商品名称', '商品一级分类', '商品二级分类', '商品三级分类', '市场价', '销售价', '成本价', '总库存']
      } else {
        this.templateKey = ['goods_name', 'first_name', 'second_name', 'three_name', 'mktprice', 'revise_price', 'cost', 'quantity', 'offline_store_pay']
        this.templateHeader = ['商品名称', '商品一级分类', '商品二级分类', '商品三级分类', '市场价', '销售价', '成本价', '总库存', '是否线下门店支付']
      }
    },
    excelSuccess ({ results }) {
      console.log(results)
      this.isExistErrorData = true;
      results = JSON.parse(
        this.templateKey.reduce((temp, item, index) => {
          return temp.replace(new RegExp(this.templateHeader[index], 'g'), item)
        }, JSON.stringify(results))
      );
      for (const item of results) {
        if (!item.goods_name) {
          this.$message.error('商品名称必填');
          return;
        }
        //去掉空格
        item.goods_name = item.goods_name.trim()
        if (item.goods_name.length > 255 || item.goods_name.length < 3) {
          this.$message.error('请输入3~255个字符的商品名称');
          return;
        }
        if (!item.first_name) {
          this.$message.error('商品一级分类必填');
          return;
        }
        if (!item.second_name) {
          this.$message.error('商品二级分类必填');
          return;
        }
        if (!item.three_name) {
          this.$message.error('商品三级分类必填');
          return;
        }

        if (!$regExp.money.test(item.mktprice) || item.mktprice.length > 255 || Math.abs(item.mktprice) != item.mktprice) {
          this.$message.error('请检查市场价格式');
          return;
        }
        if (!$regExp.money.test(item.revise_price) || item.revise_price.length > 255 || Math.abs(item.revise_price) != item.revise_price) {
          this.$message.error('请检查销售价格式');
          return;
        }
        if (this.MixinIsFormEnterprise) {
          if (!$regExp.money.test(item.cost) || item.cost.length > 255 || Math.abs(item.cost) != item.cost) {
            this.$message.error('请检查成本价格式');
            return;
          }
        }
        if (!$regExp.integer0.test(item.quantity) || item.quantity.length > 255 || Math.abs(item.quantity) != item.quantity) {
          this.$message.error('请检查总库存格式');
          return;
        }
        if (parseInt(item.quantity) > 99999999) {
          this.$message.error('库存最大不超过99999999');
          return;
        }
        if (this.deliveryMethod == 2) {//自提

          if (!item.offline_store_pay) {
            this.$message.error('是否线下门店支付必填');
            return;
          }
          if (['是', '否'].includes(item.offline_store_pay) == false) {
            this.$message.error('请检查是否线下门店支付格式');
            return;
          }
        }
      }
      if (results.length > 1000) {
        this.$message.error('要导入的表格数据量超过1000条的上限，请重新选择文件上传');
        return;
      }
      this.isExistErrorData = false;
      this.importData = results;
      this.importNum = results.length;
    },
    uploadData () {
      return new Promise(resolve => {
        if (!this.importData.length && !this.isExistErrorData) {
          this.$message.error('请导入数据');
          resolve(false);
        } else if (!this.importData.length && this.isExistErrorData) {
          this.$message.error('导入的Excel内可能存在必填项没有填写或存在重复数据，请检查表格确认无误后重新导入');
          resolve(false);
        } else {
          if (this.deliveryMethod == 2) {//自提
            this.importData.forEach(item => {
              item.offline_store_pay = item.offline_store_pay === '是' ? 2 : 1;
            })
          } else if (this.deliveryMethod == 1) {//邮寄
            this.importData.forEach(item => {
              item.delivery_method = 1;
            })
          }
          API_Goods.bulkImportSelfGoods(this.importData).then((res) => {
            const {
              sum,
              ok,
              no,
              errorExport
            } = res
            if (no === 0) {
              this.$message.success('操作成功');
              resolve(true);
              this.$emit('imported');
            } else {
              this.$emit('imported');
              let datas = {
                sum,
                ok,
                no,
                errorExport
              }
              this.$emit('downloadError', datas)
              resolve(true);
            }
          });
        }
      });
    },
    downTemplate () {
      let valueLlist = [
        {
          goods_name: '',
          first_name: '',
          second_name: '',
          three_name: '',
          mktprice: '',
          revise_price: '',
          cost: '',
          quantity: '',
          offline_store_pay: ''
        },
      ]
      if (!this.MixinIsFormEnterprise) {
        valueLlist = [
          {
            goods_name: '',
            first_name: '',
            second_name: '',
            three_name: '',
            mktprice: '',
            revise_price: '',
            quantity: '',
            offline_store_pay: ''
          }
        ]
      }
      handleDownload(
        valueLlist,
        this.templateHeader,
        this.templateKey,
        '商品导入模板'
      );
    },
  }
}
</script>

<style lang="scss">
.special-reminder {
  padding: 0;

  .el-alert {
    padding: 16px;
  }
}

.import-member-dialog {
  .tip {
    font-size: 13px;
    color: #666;
  }
}
</style>
