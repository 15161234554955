import { render, staticRenderFns } from "./shopGoodsList.vue?vue&type=template&id=51964a27&scoped=true&"
import script from "./shopGoodsList.vue?vue&type=script&lang=js&"
export * from "./shopGoodsList.vue?vue&type=script&lang=js&"
import style0 from "./shopGoodsList.vue?vue&type=style&index=0&id=51964a27&prod&lang=scss&"
import style1 from "./shopGoodsList.vue?vue&type=style&index=1&id=51964a27&prod&lang=scss&scoped=true&"
import style2 from "./shopGoodsList.vue?vue&type=style&index=2&id=51964a27&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51964a27",
  null
  
)

export default component.exports